const iframes = document.body.getElementsByTagName('iframe');
const menuBtn = document.querySelector('.menu-btn.open');
const searchInput = document.querySelector('.release-search-input');
const releases = document.querySelectorAll('.release');
const lazyImages = document.querySelectorAll('.lazy');

function initClickEvents() {
    menuBtn.addEventListener('click', function() {
        document.body.classList.add('menu-open')
    });
}

function initReleaseSearch() {
    if (!releases.length) {
        return;
    }
    if (searchInput) {
        if (window.location.hash) {
            const hash = location.hash.substr(1);
            searchInput.value = hash;
            releases.forEach(function(release) {
                if (release.dataset.searchable.toLowerCase().indexOf(hash) !== -1) {
                    release.style.display = 'block';
                } else {
                    release.style.display = 'none';
                }
            });           
        }
        searchInput.addEventListener('keyup', function() {
            search = searchInput.value.toLowerCase();
            releases.forEach(function(release) {
                if (release.dataset.searchable.toLowerCase().indexOf(search) !== -1) {
                    release.style.display = 'block';
                } else {
                    release.style.display = 'none';
                }
            });
        });
    }  
}

function initLazyloadImages() {
    const config = {
        rootMargin: '0px 0px 50px 0px',
        threshold: 0
    };
    
    let observer = new IntersectionObserver(function(entries, self) {
    entries.forEach(function(entry) {
        if(entry.isIntersecting) {
        entry.target.src = entry.target.dataset.src;
        self.unobserve(entry.target);
        }
    });
    }, config);

    lazyImages.forEach(function(image) {
        observer.observe(image);
    });
}

function addClasses() {
    for (var i = 0; i < iframes.length; i++) {
        if (
            iframes[i].src.includes('youtube') ||
            iframes[i].src.includes('vimeo')
        ) {
            var parentElem = iframes[i].parentNode;
            if (parentElem !== null) {
                if (parentElem.nodeName === 'FIGURE') {
                    parentElem.className = 'resposive-embed';
                }
            }
        }
    }
}

function bootstrap() {
    initClickEvents();
    initReleaseSearch();
    initLazyloadImages();
    addClasses();
}
document.addEventListener('DOMContentLoaded', bootstrap, false);